<template>
  <div class="orderList">
     <div class="title">
        <img class="blocks" src="../../../../src/assets/image/black.png" alt="" @click="goblack">
        <span>申请退货</span>
        <img  @click="openService()" class="customer" src="../../../../src/assets/image/customer.png" alt="">
     </div>
    <div class="goods" v-for="(item, index) in orderInfo.sub" :key="index">
        <img :src="item.topPic" alt="">
        <div class="goodinfo">
        <div class="name">{{item.name}}</div>
        <div class="tags"><span>{{item.skuAttr}}</span><span>×{{item.number}}</span></div>
        <div class="price">
            ￥{{item.skuPrice}}
        </div>
        </div>
    </div>
    <!-- 退货信息 -->
    <div class="type_list">
        <div class="type_title">退货信息</div>
        <van-cell :value="refundText" is-link @click="show = true">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
                <span class="custom-title">退款原因</span>
            </template>
        </van-cell>
        <van-cell center title="商品金额" :value="'￥' + orderInfo.amount" label="购买该商品时，实际支付的金额(不包含运费)" />
        
        <van-cell value="原支付返回" is-link @click="show1 = true">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
                <span class="custom-title">退款方式</span>
            </template>
        </van-cell>
    </div>
    <!-- 退货信息 -->
    <div class="info_box">
        <div class="info_title">退货信息</div>
        <div class="info_content">
            <van-field
                v-model="params.note"
                rows="2"
                autosize
                type="textarea"
                maxlength="50"
                placeholder="描述有助于卖家更好的处理售后问题"
                show-word-limit
                />
                <div class="up_div">
                    <van-uploader :after-read="afterRead" @delete="imageDelete" v-model="fileList" multiple :max-count="9" />
                </div>
        </div>
    </div>
    <bottomList></bottomList>
    <div class="next_but" @click="applyRefund">
        <div>下一步</div>
    </div>
    <van-popup position="bottom" v-model="show1">
        <div class="pop_title">
            <span>退款方式</span>
            <van-icon size="20rem" name="cross" @click="show1 = false"/>
        </div>
        <div class="pop_fshi">
            <img src="../../../../src/assets/image/returns.png" alt="">
            <span>原支付返回</span>
        </div>
        <div class="queren"  @click="show1 = false">关闭</div>
    </van-popup>
    <van-popup position="bottom" v-model="show">
        <div class="pop_title">
            <span>申请原因</span>
            <van-icon size="20rem" name="cross" @click="show = false"/>
        </div>
        <van-radio-group v-model="radio">
            <van-cell-group>
                <van-cell v-for="(item, index) in list" :key="index" :title="` ${item}`" clickable  @click="doSelect(index)" >
                <template #right-icon>
                    <van-radio :name="index" />
                </template>
                </van-cell>
            </van-cell-group>
            </van-radio-group>
        <div class="queren" @click="doSave">确认</div>
    </van-popup>
  </div>
</template>

<script>
import { getOrderInfo } from '@/api/order'
import { ReasonRefund, applyRefund, getOss } from '@/api/service' // 退款理由 申请退货 图片上传
const OSS = require('ali-oss');
export default {
  name: "returns",
  data() {
    return {
      orderInfo: {},
      message: '',
      fileList: [],
      show: false,
      show1: false,
      list: [],
      radio: 0,
      refundText: '请选择',
      domain: '',
      params: {
        orderId: '',
        type: 1,
        reason:  '', // 退货原因
        note: '', // 退货信息
        images: [], // 退货图片
      },
      files: [],
      imgaeindex: 0
    };
  },

  mounted() {
    if(this.$route.params.orderId) {
        localStorage.setItem('orderId', this.$route.params.orderId)
    } else {
        this.$route.params.orderId = localStorage.getItem('orderId')
    }
    this.getInfo()
    this.getReasonRefund()
  },

  methods: {
    // 跳转客服
    openService() {
        window.open('https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN&from=blD4dOkH24')
    },
    // 获取退款理由
    getReasonRefund() {
        ReasonRefund().then(res => {
            this.list = res.data
        })
    },
    // 选择理由
    doSelect(index) {
        this.radio = index
    },
    // 确定理由
    doSave() {
        this.refundText = this.list[this.radio]
        this.show = false
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
    // 获取订单信息
    getInfo() {
      getOrderInfo(this.$route.params.orderId).then(res => {
        this.orderInfo = res.data
      })
    },
    // 上传触发
    afterRead(val) {
        var that = this
        this.files.push(val)
        // 获取oss配置
        getOss().then(res => {
            that.domain = res.data.domain
            // 上传至oss
            that.client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: res.data.region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: res.data.accessKeyId,
                accessKeySecret: res.data.accessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: res.data.securityToken,
                // 填写Bucket名称。
                bucket: res.data.bucketName,
            });
            const result = that.client.put("returns/" + `${Date.parse(new Date())}` +'.jpg', val.file);
            result.then((res, cal) => {
                that.params.images.push(res.res.requestUrls[0])
            })
        })
    },
    // 删除触发
    imageDelete(val) {
        this.imgaeindex = this.files.indexOf(val)
        this.params.images.splice(this.imgaeindex, 1)
    },
    // 确定退货
    applyRefund() {
        var that = this
        this.params.orderId = this.$route.params.orderId
        this.params.reason = this.refundText
        if (!this.params.reason) {
            return that.$layer.quicktips('请选择退款原因')
        }
        if (!this.params.note) {
            return that.$layer.quicktips('请填写退款信息')
        }
        // 退款
        applyRefund(that.params).then(res => {
            if (res.state == 200) {
                that.$layer.quicktips('申请退款成功')
                // 跳转服务详情
                that.$router.push({
                    name: `serviceOrderDetail`,
                    params: {
                        orderId: that.$route.params.orderId
                    }
                })
                
            }
        })
    },
  },
};
</script>

<style lang="less" scoped>
.orderList{
    width: 100vw;
    min-height: 100vh;
   background: #F6F7FA;
   display: inline-block;
   padding-bottom: 100rem;
}
.title{
    width: 100%;
    position: fixed;
    top: 0rem;
    left: 0rem;
    z-index: 11;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    .blocks{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 17rem;
        color: #333333;
    }
    .customer{
        position: absolute;
        right: 14rem;
        width: 23rem;
        height: 21rem;
    }
}

.goods{
  margin-top: 55rem;
  display: flex;
  padding: 15rem;
  background: #fff;
  img{
    width: 98rem;
    height: 98rem;
  }
  .goodinfo{
    margin-left: 8rem;
    width: calc(100% - 116rem);
    .name{
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 14rem;
      color: #000000;
    }
    .tags{
      display: flex;
      margin-top: 5rem;
      justify-content: space-between;
      span{
          font-family: PingFangSC, PingFang SC;
          font-weight: 300;
          font-size: 13rem;
          color: #939297;
      }
    }
    .price{
      font-family: DINPro, DINPro;
      font-weight: 500;
      font-size: 14rem;
      color: #000000;
      margin-top: 32rem;
    }
  }
}
.type_list{
    width: 100vw;
    background: #FFFFFF;
    margin-top: 10rem;
    .type_title{
        padding-left: 16rem;
        height: 50rem;
        line-height: 50rem;
        font-weight: 400;
        font-size: 14rem;
        color: #969CA8;
    }
}
.info_box{
    margin-top: 8rem;
    background: #fff;
    padding: 12rem 16rem;
    .info_title{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14rem;
        color: #333333;
    }
    .info_content{
        margin-top: 12rem;
    }
    .info_content, .van-field{
        background: #F6F7FA;
    }
    /deep/.van-uploader__upload{
        background: #fff;
    }
    .up_div{
        padding: 10rem;
    }
}
.next_but{
    padding: 17rem 16rem;
    background: #fff;
    position: fixed;
    bottom: 0rem;
    left: 0rem;
    width: calc(100vw - 32rem);
    div{
        height: 48rem;
        line-height: 48rem;
        background: #E0BFB1;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16rem;
        color: #333333;
    }
}
.pop_title{
    text-align: center;
    position: relative;
    padding: 20rem 0rem;
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 18rem;
        color: #000000;
    }
    .van-icon{
        position: absolute;
        right: 20rem;
    }
}
/deep/.van-checkbox-group{
    padding:20rem 0rem 40rem 0rem;
}
/deep/ .van-radio__icon--checked .van-icon{
    background-color: #000;
    border-color: #000;
}
.queren{
    height: 48rem;
    line-height: 48rem;
    background: #E0BFB1;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16rem;
    color: #333333;
    width: calc(100vw - 32rem);
    margin: auto;
    margin-bottom: 20rem;
}
.pop_fshi{
    padding: 20rem 16rem 20rem 16rem;
    img{
        width: 24rem;
        height: 21rem;
        vertical-align: top;
    }
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 15rem;
        color: #000000;
        margin-left: 8rem;
    }
    tt{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 15rem;
        color: #E0BFB1;
    }
}
.pop_con{
    padding: 0rem 16rem 16rem 16rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13rem;
    color: #6E7279;
    line-height: 20rem;
}
</style>
